<template>
  <div class="maintenance">
    <div class="container">
      <div class="row">
        <div class="col-6 offset-3 text-center card p-5">
          <h1 class="m-0 text-primary text-center">
            We will be back soon!
          </h1>

          <div class="mt-4">
            <span class="d-block">GreenLink is currently undergoing scheduled maintenance.</span>
            <span class="d-block">We should be back shortly. Thank you for your patience.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Maintenance",
};
</script>
